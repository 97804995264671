.main-body {
  padding: 0;
  margin-top: 75px;
}

.app-login {
  top: 0;
  bottom: 0;
  position: absolute;
  left: 0;
  right: 0;
  max-height: 12.5rem;
}

.app-login-inner {
  border: 1px solid #dadada;
  border-radius: 0.25rem;
  padding: 1.5rem;
  text-align: center;
  height: 100%;
  margin: 0 1rem;
}

.app-login-inner button {
  margin-top: 2rem;
  font-size: 1.25rem;
  background: #fff;
  border: 0;
  color: #07c;
  line-height: 1.5rem;
}
.app-login-inner button:hover {
  text-decoration: underline;
  color: #0095ff !important;
}


@media (max-width: 990px) {
  .main-body {
    margin-top: 60px;
  }
}