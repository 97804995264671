.search-page {

  background-color: #fff;

  .search-text-wrapper {
    margin: 3.5rem 0 2rem 0;
    text-align: center;

    .input-holder {

      input[type="text"] {
        width: 100%;
        padding: 0.5rem 1rem;
        font-size: 24px;
      }

      input[type="button"] {
        position: absolute;
        margin-left: -3rem;
        padding: 0.5rem;
        border: 0;
        background: #0000;
        font-size: 24px;
        line-height: 24px;
        top: 50%;
        transform: translateY(-50%);
        font-family: 'Arial';
      }
    }
    button[type="submit"] {
      width: 50px;
      text-align: center;
      padding: 0.5rem 0;
      font-size: 18px;
      border: 1px solid #dadada;
      background: #efefef;
      border-left: 0;
    }
  }

  .no-result {
    width: 100%;
    text-align: center;
  }

}

@media (max-width: 990px) {

  .search-page {
    .search-text-wrapper {

      .input-holder {
        input[type="text"] {
          font-size: 18px;
        }
      }
    }
  }

}