
.catalog-item-container {
  padding: 1.5rem 1.5rem 5rem 1.5rem;

  .catalog-info-wrapper {

    .link-back {
      background: none;
      border: 0px;
      position: relative;
      font-size: 22px;
      color: #000;
      font-weight: 200;
      right: -3px;

      &:hover {
        text-decoration: none;
        right: 0px;
        transition: all 0.3s ease-in-out;
      }
    }

    img.icon {
      width: 8rem;
      margin: 1rem 0;
    }

    span.date-added {
      font-size: 12px;
      color: #999;
      width: 100%;
      display: block;
    }

    p.item-desc {
      font-size: 14px;
      white-space: pre-wrap;
    }

    .values, .cases, .priority-workloads {
      ul {
        font-size: 14px;
        padding-left: 1.25rem;

        li {
          margin-top: 0.25rem;
        }
      }
    }

    .consumption {
      .measure {
        span {
          width: 18px;
          height: 8px;
          background: #CFCFCD;
          display: inline-block;
          border-radius: 4px;
          margin-right: 4px;

          &.use {
            background: #55575B;
          }
        }
      }
    }

    .type-of-product {
      span {
        padding: 2px 10px;
        border-radius: 12px;
        font-size: 14px;
        margin-top: 6px;
      }
    }

  }

  .comments-container {

    border-top: 1px solid #e5e5e5;
    margin: 2rem 0;

    .enter-comment {
      padding: 2rem 0.75rem;

      .profile-image {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        margin-right: 0.5rem;
      }

      textarea {
        border: 1px solid #a1a1a1;
        border-radius: 4px;
      }

      button {
        margin: 1rem 0;
        color: #fff;
        background: #55575B;
        font-size: 14px;
        padding: 0.5rem 2rem;
        border-radius: 5px;
        border: 0;
      }
    }

    .comments-list {
      padding: 0.75rem 0;

      .profile-image {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        margin-right: 0.5rem;
        border: 1px solid #b3b3b3;
      }

      .box-comment {
        border: 1px solid #E0E0E0;
        padding: 1rem;
        border-radius: 4px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
        margin: 0 0 1.5rem 0;
        width: 100%;
        font-size: 14px;

        b,i {
          display: block;
        }
        i {
          font-style: normal;
          font-size: 12px;
          color: #999;
        }
        p {
          margin: 0.5rem 0;
        }
      }

    }


  }

  .file-upload-wrapper {

    .upload-section {
      padding: 0;
      border: 1px solid #E0E0E0;
      border-radius: 4px;
      margin: 1rem 0;

      .file-wrap {
        padding: 1rem 2rem;
        margin: 1rem 0;
        border-right: 1px solid #E0E0E0;

        input[type='file'] {
          width: 100%;
        }

        progress[value] {
          /* Reset the default appearance */
          -webkit-appearance: none;
          appearance: none;
          width: 60%;
          height: 0.75rem;
          margin-top: 0.5rem;

          &::-webkit-progress-bar {
            background-color: #eee;
            border-radius: 2px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
          }
        }
      }

      .desc-wrap {
        padding: 1rem 2rem;
        margin: 1rem 0;
      }
    }

    button {
      background-color: #4e4e4e;
      color: #fff;
      padding: 0.5rem 2rem;
      border-radius: 4px;
      font-size: 14px;
      border: 0;
    }

  }

  .media-wrapper {
    margin-top: 2.5rem;

    h5 {
      margin-bottom: 1rem;
    }

    .media-list {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      .media-item-wrap {
        flex: 22% 0 0;
        margin-right: 0.6rem;

        .media-item {
          background: #b0d8f9;
          border: 1px solid #b9b9b9;
          text-align: center;
          width: 150px;
          height: 100px;
          margin: auto;

          img {
            width: 40%;
            top: 50%;
            position: relative;
            transform: translateY(-50%);
          }
        }

        p { text-align: center; }
      }
    }
  }

}

@media (min-width: 992px) {

  .catalog-item-container {
    margin-left: 5rem;
  }

  .link-back {
    margin-left: -7rem;
  }
}

@media (min-width: 760px) {

  .catalog-item-container {
    padding-left: 3rem;
  }

}
