.admin-form-wrapper {
  padding: 2.5rem;
  color: #000000;

  h2 {
    margin-bottom: 2rem;
  }

  form {
    padding-bottom: 4rem;

    .input-wrap{
      margin-bottom: 2rem;

      label {
        margin-bottom: 0.25rem;
      }
    }

    .info-wrap {
      display: inline-block;
      .info {
        border: 0;
        margin: 0 5px;
        text-align: center;
        cursor: pointer;
        font-weight: bold;
        font-size: 14px;
        padding: 0;

        &:hover {
          color: #000080;
        }
      }

      .img-wrap {
        position: absolute;
        width: 60%;
        left: 2.5rem;
        border: 1px solid #E3E3E3;
        padding: 1rem;
        background: #fff;
        border-radius: 10px;
        margin-bottom: 4rem;
        img {
          width: 100%;
        }
        i {
          font-size: 14px;
        }
      }
    }

    input {
      width: 100%;

      &[type="text"] {
        border: 1px solid #B3B3B3;
        border-radius: 4px;
        padding: 4px 8px;
      }
    }

    select {
      width: 100%;
      border: 1px solid #B3B3B3;
      border-radius: 4px;
      padding: 4px 8px;
    }

    textarea {
      width: 100%;
      border: 1px solid #B3B3B3;
      border-radius: 4px;
      padding: 4px 8px;
    }

    button.btn-submit {
      background-color: #4e4e4e;
      color: #fff;
      padding: 0.5rem 2rem;
      border-radius: 4px;
      font-size: 14px;
      border: 0;
      width: 100%;

      span {
        cursor: pointer;
        display: inline-block;
        position: relative;
        transition: 0.5s;

        &:after {
          content: '\00bb';
          position: absolute;
          opacity: 0;
          top: 0;
          right: -20px;
          transition: 0.5s;
        }
      }

      &:hover {
        span {
          padding-right: 1rem;

          &:after {
            opacity: 1;
            right: 0;
          }
        }
      }
    }

    .success {
      color: #fff;
      background: #62b6ac;
      text-align: center;
      padding: 0.5rem;
      border-radius: 4px;
      margin-top: 1.5rem;
      font-weight: 500;
    }

    .text-muted {
      font-size: 14px;
    }

    .toggle-pw {
      padding: 0.75rem;
      text-align: left;
      background: #dadada;
      border: 0;
      font-weight: 700;

      &.active {
        background-color: #1d9eea;
        color: #fff;
        margin-bottom: 1.5rem;
      }

      img {
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        right: 2rem;
      }
    }

    .pw-wrapper {
      display: none;
      &.show {
        display: block;
      }
    }
  }
}

.errors {
  color: red;
  font-size: 13px;
}

// multi-select

.pillar-wrapper {
  border: 1px solid #B3B3B3;
  margin-bottom: 1.5rem;
  padding: 1.25rem 1rem;
  position: relative;

  .pillar-header {
    font-size: 14px;
    background: #fff;
    top: -16px;
    position: absolute;
    padding: 4px;
    max-width: 90%;
  }

  .button-wrap {

    &:not(:last-of-type) {
      margin-bottom: 0.75rem;
    }

    label {
      margin: 0 !important;
      line-height: 20px;
      font-size: 14px;
      cursor: pointer;
    }
    input[type="checkbox"] {
      position: absolute;
      z-index: -1;
      opacity: 0;
    }
    span {
      width: 20px;
      height: 20px;
      display: inline-block;
      border: 1px solid #999;
      margin-right: 6px;
      border-radius: 4px;
      vertical-align: middle;
    }
    input:checked + span {
      background: url("/Images/check.svg") no-repeat center #1d9eea;
    }
    input:focus + span {
      outline:none; 
      border:1px solid #4D90FE;
      -webkit-box-shadow: 0px 0px 5px  #4D90FE;
      box-shadow: 0px 0px 5px  #4D90FE;
    }
  }
}

@media (max-width: 550px) {
  .admin-form-wrapper form .info-wrap .img-wrap {
    width: 75%;
  }

  .pillar-wrapper.xp {
    padding-top: 2.5rem !important;
  }
}