.popout {
  position: fixed;
  display: flex;
  right: 0;
  width: 270px;
  max-width: 70%;
  height: 100%;
  top: 0;
  z-index: 200;
  background: white;
  box-sizing: border-box;
  transition: transform 0.3s ease-out;
  flex-flow: column;

  ul.popout-list {
    padding: 0;

    > li {
      list-style: none;
      border-bottom: 1px solid #dadada;
  
      button {
        color: #007bff;
        text-decoration: none;
        background-color: #0000;
        border: 0;
        padding: 1rem 1.5rem;
        width: 100%;
        text-align: left;
  
        &:hover {
          color: #0056b3;
          text-decoration: underline;
        }
      }
    }
  }



  &__profile-image {
    width: 50px;
    border-radius: 25px;
  }

  &__userInfo {
    font-size: 16px;
  }

  &__sign-in {
    button {
      background: transparent;
      border: 0px;
      line-height: 16px;
      color: #006dae;
      padding: 5px;

      &:hover {
        text-decoration: underline;
        color: #1400ae;
      }
    }
  }

  &__user-info {
    display: inline-block;
    vertical-align: middle;

    span {
      padding: 0 5px;
      text-align: center;
      font-size: 14px;
      line-height: 14px;
      display: block;
    }
  }

  a {
    display: block;
    padding: 1rem 1.5rem;
  }
}

@media (min-width: 991px) {
  .popout {
    display: none;
  }
}

.open {
  transform: translateX(0);
}

.close {
  transform: translateX(100%);
}