.card-wrapper {
  margin: 0.75rem 0;

  button.card {
    padding: 1rem;
    text-align: left;
    min-height: 180px;
    border: 1px solid #e0e0e0;
    width: 100%;

    &:hover {
      border: 1px solid #60b5af;
      box-shadow: 2px 2px 4px #acacac;
    }

    p {
      font-size: 14px;
    }

    img {
      width: 2.75rem;
      height: 2.75rem;
    }

    b {
      padding-left: 0.75rem;
      line-height: 3rem;
    }

    span.type-of-product {
      padding: 2px 10px;
      border-radius: 12px;
      font-size: 14px;
    }

    span.date-added {
      font-size: 12px;
      color: #999;
      width: 100%;
      margin-top: 1.5rem;
    }
  }
  
}

// @media (max-width: 990px) {

//   .card-wrapper {
//     button.card {

//       p{
//         font-size: 14px;
//       }
//     }
//   }

// }