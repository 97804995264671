.section-404 {
  height: 84vh;
  .wrapper-404 {
    width: 50%;
    margin: auto;
    max-height: 400px;
    position: relative;
    top: 1.5rem;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;

    span[role="img"] {
      font-size: 6rem;
    }

    h5 {
      font-weight: normal;
      color: #444;
    }
  }
}