.header {

  width: 100%;
  height: 75px;
  top: 0;
  z-index: 2;
  position: fixed;
  background: #fff;
  color: #000;
  box-shadow: 1px 1px 7px #8d8d8d;

  &__container {
    line-height: 75px;
    margin: 0 3rem;

    .logo {
      width: 100px;
      display: inline-block;
      margin-left: -1.5rem;
      vertical-align: middle;
    }
  }

  &__profile-image {
    width: 50px;
    border-radius: 25px;
  }

  &__sign-in {
    float: right;

    button {
      background: transparent;
      border: 0px;
      line-height: 16px;
      color: #006dae;
      padding: 5px;

      &:hover {
        text-decoration: underline;
        color: #1400ae;
      }
    }
  }

  &__userInfo {
    font-size: 16px;
  }

  &__user-info {
    display: inline-block;
    vertical-align: middle;

    span {
      padding: 0 5px;
      text-align: center;
      font-size: 14px;
      line-height: 14px;
      display: block;
    }
  }

  a {
    margin: 0 1rem;
  }

}

.signIn {
  display: none;
}

.burger {
  display: none;
}

.mobileHeader {
  position: fixed;
  width: 100%;
  height: 60px;
  top: 0;
  z-index: 2;
  background: #fff;
  box-shadow: 1px 1px 7px #8d8d8d;
  display: none;

  .logo-mobile {
    width: 100px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 1.5rem;
  }
}

.menu-search {
  &:after {
    content:'\1F50D';
    font-size: 12px;
    margin-left: 3px;
    opacity: 0.7;
    z-index: 1;
  }
}

.menu-home {
  &:after {
    content: '\1F3E0';
    font-size: 12px;
    margin-left: 3px;
    opacity: 0.7;
  }
}

//---------------------------------

@media (max-width: 990px) {
  .header {
    display: none;
  }

  .mobileHeader {
    display: block;
  }

  .burger {
    width: 40px;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    margin: 1rem 0.5rem;

  }

  .burger span {
    width: 90%;
    height: 4px;
    margin: 3px;
    background-color: #006dae;
  }

  .signIn {
    display: flex;
    position: absolute;
    top: 0;
    right: 40px;
    margin: 0.75rem 1.25rem;

    button {
      background: transparent;
      border: 0px;
      line-height: 16px;
      color: #006dae;
      padding: 10px 5px;

      &:hover {
        text-decoration: underline;
        color: #1400ae;
      }
    }

    img {
      width: 35px;
    }

    .header__user-info {
      & span {
        display: none;
      }
    }
  }
}