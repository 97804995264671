.home-wrapper {
  background-color: #ECECEC;
  padding-bottom: 5rem;

  .cards-container {
    padding: 1.5rem 25px;

    .industry-container {
      margin: 1.5rem 0;

      h5 {
        display: inline-block;
      }
      a {
        float: right;
        text-transform: uppercase;
        font-family: 'Arial';
        font-size: 15px;
      }
    }

    &.search,&.category {
      padding: 1.5rem 25px;
    }
  }

  .splash-div {
    background-color: #fff;

    & > div {
      display: inline-block;
      padding: 2.5rem;
      z-index: 1;

      h2 {
        margin-bottom: 1rem;
      }
    }

    img {
      max-width: 550px;
      position: absolute;
      right: 0;
      bottom: 0;
      opacity: 0.5;
    }
  }

}


//--- Mobile phone
@media (max-width: 769px) {

  .home-wrapper {

    .splash-div {
      
      img {
        max-width: 400px;
        margin-top: -4.5rem;
      }

    }

  }

}