.gg-onboarding-container {

  .header-wrapper {
    background-color: #F0F8FF;

    p.sub-header {
      font-weight: bold;
      color: #5281C2;
      padding-left: 15rem;
      margin-top: 3%;
    }

    h1 {
      padding-left: 15rem
    }

    p.item-desc {
      padding-left: 15rem;
    }

    img.icon {
      width: 22rem;
      margin: -12% 15% 2% 0%;
    }

    .btn-onboarding {
      background-color: #41AF49;
      border: none;
      border-radius: 12px;
      color: white;
      text-align: center;
      font-weight: bold;
      margin-left: 15rem;
      margin-top: 1.5%;
      padding: 14px 40px;
    }

    .btn-onboarding-text {
      color: white;
      font-weight: bold;
    }
  }

  .form-instruction {
    padding-left: 15rem;
    padding-top: 1rem;

    p.sub-header-3 {
      font-weight: bold;
      font-style: italic;
      margin-top: 1rem;
      font-size: large;
    }

    button.go-back {
      margin-left: 1rem;

    }

  }

  .contact {
    float: right;
    position: fixed;
    bottom: 4rem;
    right: 2rem;
  }

  .contact-text {
    font-size: small;
    margin: 0;
    padding: 0;
  }

  img.profile {
    width: 4rem;
    margin-right: 1rem;
  }

  .google-garage-onboarding-form {
    padding-left: 15rem;
    padding-top: 2rem;

    .circle-1 {
      height: 40px;
      width: 40px;
      text-align: center;
      vertical-align: middle;
      border-radius: 50%;
      background-color: #3F89DE;
      color: white;
      font-weight: bold;
      padding-top: 0.5rem;
    }

    .part1-title-text {
      padding-top: 0.5rem;
      padding-left: 1rem;
      font-weight: bold;
      color: #3F89DE;
    }

    .part1-line {
      background-color: #3F89DE;
      margin-top: -0.7rem;
      width: 40rem;
      margin-left: 1rem;
    }

    .client-data-yes {
      background-color: #DAE3F3;
      padding-left: 1rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      width: 30rem;
      margin-bottom: 1rem;
    }

    .client-data-yes-details {
      display: none;
    }

    .client-data-yes:hover .client-data-yes-details {
      display: block;
      visibility: visible;
    }

    .part1-medium {
      background-color: #D6DCE5;
      padding-left: 1rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      width: 30rem;
      margin-bottom: 1rem;
    }

    .part1-medium-details {
      display: none;
    }

    .part1-medium:hover .part1-medium-details {
      display: block;
      visibility: visible;
    }

    .part1-large {
      background-color: #E2F0D9;
      padding-left: 1rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      width: 30rem;
      margin-bottom: 1rem;
    }

    .part1-large-details {
      display: none;
    }

    .part1-large:hover .part1-large-details {
      display: block;
      visibility: visible;
    }

    .part1-custom {
      background-color: #FBE5D6;
      padding-left: 1rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      width: 30rem;
      margin-bottom: 1rem;
    }

    .part1-text-input-custom {
      border-top: none;
      border-right: none;
      border-left: none;
      border-width: thin;
      height: 1.5rem;
      margin-bottom: 1rem;
      width: 30rem;
    }

    .btn-submit {
      background-color: #7F7F7F;
      border: none;
      border-radius: 12px;
      color: white;
      text-align: center;
      font-weight: bold;
      margin-left: 23rem;
      padding: 8px 30px;
      margin-bottom: 2rem;
    }

    .part2-title {
      margin-top: 2rem;
    }

    .circle-2 {
      height: 40px;
      width: 40px;
      text-align: center;
      vertical-align: middle;
      border-radius: 50%;
      background-color: #41AF49;
      color: white;
      font-weight: bold;
      padding-top: 0.5rem;
    }

    .part2-title-text {
      padding-top: 0.5rem;
      padding-left: 1rem;
      font-weight: bold;
      color: #41AF49;
    }

    .part2-line {
      background-color: #41AF49;
      margin-top: -0.7rem;
      width: 40rem;
      margin-left: 1rem;
    }

    .text-input {
      border-width: thin;
      width: 30rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
    
    .text-input-material {
      width: 30rem;
      margin-bottom: 1rem;
    }

    .data-type {
      padding-left: 1rem;
    }

    .data-type-select {
      margin-left: 4.5rem;
      width: 10rem;
      padding-top: -1rem;
    }

    .data-classification {
      padding-left: 1rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    .data-classification-select {
      margin-left: 1rem;
      width: 10rem;
      padding-top: -1rem;
    }

    .part3-title {
      margin-top: 2rem;
    }

    .circle-3 {
      height: 40px;
      width: 40px;
      text-align: center;
      vertical-align: middle;
      border-radius: 50%;
      background-color: #F8AF0A;
      color: white;
      font-weight: bold;
      padding-top: 0.5rem;
    }

    .part3-title-text {
      padding-top: 0.5rem;
      padding-left: 1rem;
      font-weight: bold;
      color: #F8AF0A;
    }

    .part3-line {
      background-color: #F8AF0A;
      margin-top: -0.7rem;
      width: 40rem;
      margin-left: 1rem;
    }

    .part3-choice {
      padding-bottom: 1rem;
    }

    .part3-text-input-other {
      border-top: none;
      border-right: none;
      border-left: none;
      border-width: thin;
      height: 1.5rem;
      margin-left: 1rem;
      width: 20rem;
    }

    .part3-choice-other {
      margin-left: 0rem;
    }

    .project-name-input {
      margin-bottom: 1rem;
      height: 2rem;
      width: 14.5rem;
      border-width: thin;
    }

    .repository-name-input {
      border-width: thin;
      margin-left: 1rem;
      margin-bottom: 1rem;
      width: 14.5rem;
      border-width: thin;
    }

    .part3-email-input {
      border-width: thin;
      margin-left: 1rem;
      margin-bottom: 1rem;
      width: 14.5rem;
    }

    .part3-name-input {
      border-width: thin;
      margin-left: 1rem;
      margin-bottom: 1rem;
      width: 14.5rem;
    }

    .part3-admin-access {
      margin-left: 1rem;
    }

    .part4-title {
      margin-top: 2rem;
    }

    .circle-4 {
      height: 40px;
      width: 40px;
      text-align: center;
      vertical-align: middle;
      border-radius: 50%;
      background-color: #EA4831;
      color: white;
      font-weight: bold;
      padding-top: 0.5rem;
    }

    .part4-title-text {
      padding-top: 0.5rem;
      padding-left: 1rem;
      font-weight: bold;
      color: #EA4831;
    }

    .part4-line {
      background-color: #EA4831;
      margin-top: -0.7rem;
      width: 40rem;
      margin-left: 1rem;
    }

    .user-info {
      margin-bottom: 0.5rem;
    }

    .user-info-email {
      border-width: thin;
      margin-left: 1rem;
      margin-bottom: 1rem;
      height: 2rem;
      width: 14.5rem;
    }

    .user-info-name {
      border-width: thin;
      margin-left: 1rem;
      margin-bottom: 1rem;
      width: 14.5rem;
    }

    .add-container {
      margin-left: 1rem;
    }

    .add-symbol {
      border: 2px solid grey;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      text-align: center;
      vertical-align: center;
      padding-right: 0.25rem;
      padding-left: 0.25rem;
      font-weight: bold;
      color: grey;
    }

    .add-text {
      margin-left: 1rem;
      color: grey;
      margin-bottom: 2rem;
    }
    .submitButton {
      margin-bottom: 2rem;
      padding: 8px 30px;
      font-weight: bold;
    }
  }

}

@media (max-width: 550px) {
  .admin-form-wrapper form .info-wrap .img-wrap {
    width: 75%;
  }

  .pillar-wrapper.xp {
    padding-top: 2.5rem !important;
  }
}
