.google-garage-container {
    padding: 1.5rem 1.5rem 5rem 1.5rem;
    .catalog-info-wrapper {

        .link-back {
          background: none;
          border: 0px;
          position: relative;
          font-size: 22px;
          color: #000;
          font-weight: 200;
          right: -3px;
    
          &:hover {
            text-decoration: none;
            right: 0px;
            transition: all 0.3s ease-in-out;
          }
        }
    
        img.icon {
          width: 8rem;
          margin: 1rem 0;
        }
       
    
        span.date-added {
          font-size: 12px;
          color: #999;
          width: 100%;
          display: block;
        }
    
        p.item-desc {
          font-size: 14px;
          white-space: pre-wrap;
        }
    
        .values, .cases, .priority-workloads {
          ul {
            font-size: 14px;
            padding-left: 1.25rem;
    
            li {
              margin-top: 0.25rem;
            }
          }
        }
    
        .consumption {
          .measure {
            span {
              width: 18px;
              height: 8px;
              background: #CFCFCD;
              display: inline-block;
              border-radius: 4px;
              margin-right: 4px;
    
              &.use {
                background: #55575B;
              }
            }
          }
        }
    
        .type-of-product {
          span {
            padding: 2px 10px;
            border-radius: 12px;
            font-size: 14px;
            margin-top: 6px;
          }
        }
    
    }
  
    .header-wrapper {
      background-color: #F0F8FF;
  
      p.sub-header {
        font-weight: bold;
        color: #5281C2;
        padding-left: 15rem;
        margin-top: 3%;
      }
  
      h1 {
        padding-left: 15rem
      }
  
      p.item-desc {
        padding-left: 15rem;
      }
  
      img.icon {
        width: 22rem;
        margin: -12% 15% 2% 0%;
      }
  
      .btn-onboarding {
        background-color: #41AF49;
        border: none;
        border-radius: 12px;
        color: white;
        text-align: center;
        font-weight: bold;
        margin-left: 15rem;
        margin-top: 1.5%;
        padding: 14px 40px;
      }
  
      .btn-onboarding-text {
        color: white;
        font-weight: bold;
      }
  
    }
  
    .catalog-service-wrapper {
        .link-back {
            margin-left: -7rem;
          }
  
        p.sub-header-2 {
          font-weight: bold;
          text-align: center;
          padding: 15px;
          width: 100%;
          margin-top: 2%;
          font-size: large;
          margin-left: 3%;
        }

        .googleIcon {
            display: flex;
            justify-content: center;
            align-items: center;
        }
  
        .data-analytics {
          background-color: #FDF5E6;
          width: 85%;
          float: left;
          margin-left: 20%;
          /* margin-right: 10%; */
          border-top: 3px solid #5281C2;
        }
  
        .infrastructure {
          background-color: #FDF5E6;
          width: 28%;
          float: right;
          margin-right: 10%;
          border-top: 3px solid #5281C2;
        }
  
        img.icon {
          width: 6rem;
          margin: 1rem 0;
          margin-left: 5%
        }
  
        h4 {
          float: right;
          margin-right: 28%;
          margin-top: 15%;
        }
  
        p {
          margin-left: 5%;
        }
  
        .contact {
          float: right;
          margin-right: -63%;
          margin-top: 5%;
        }
  
        .contact-text {
          font-size: small;
          margin: 0;
          padding: 0;
        }
  
        img.profile {
          width: 4rem;
          margin-top: 10%;
          margin-bottom: 10%;
        }
  
        img.guiding-principle {
          float: left;
          margin-left: 5%;
          margin-top: 5%;
        }
  
    }
  
    p.sub-header-3 {
      font-weight: bold;
      padding: 1px;
      width: 100%;
      margin-top: 2%;
      font-size: small;
      margin-left: 10%;
    }
  
    .go-back {
      font-style: italic;
      margin-left: 1em;
      font-size: larger;
    }

    .environment-setup-link {
      // font-style: italic;
      // margin-left: 5em;
      font-size: 2em;
    }

    .btn-setup-environment {
      background-color: #41AF49;
      border: none;
      border-radius: 12px;
      color: white;
      text-align: center;
      font-weight: bold;
      margin-top: 1.5%;
      padding: 14px 40px;
      margin-bottom: 5px;
    }
  
    .service-wrapper {
      margin: 2%;
      background-color: #f5f5fd;
      width: 100%;  
      border-top: 3px solid #5281C2;
  
      .individual-service {
        width: 25%;
      }
  
      .service-title {
        text-align: center;
        margin-left: -3%;
        margin-top: 1%;
        font-size: large;
      }
  
      .service-image {
        width: 25%;
        margin-left: 27%;
      }
  
      .individual-service-title {
        margin-left: 25%;
      }
  
      .individual-service-environment {
        margin-left: 17%;
      }
  
      .individual-service-desc {
        width: 60%;
        margin-left: 15%;
      }
  
    }
  }