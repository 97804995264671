.menu-industries {
  display: inline-block;
  line-height: 18px;
  margin: 0 1rem;
  position: relative;
  z-index: 2;

  button {
    color: #007bff;
    text-decoration: none;
    background-color: #0000;
    border: 0;
    width: 100%;
    text-align: center;
    padding: 0;
    line-height: 18px;

    &:hover {
      color: #0056b3;
      text-decoration: underline;
    }

    i {
      font-size: 12px;
      font-style: normal;
    }
  }

  ul {
    position: absolute;
    border: 1px solid #dadada;
    border-bottom: 0;
    background: #fff;
    list-style: none;
    padding: 0;
    min-width: 200px;
    margin-top: 0.5rem;

    li {
      a {
        display: block;
        margin: 0;
        padding: 1rem 0.75rem;
        border-bottom: 1px solid #dadada;
        white-space: nowrap;
      }
    }
  }
}

@media (max-width: 990px) {

  .menu-industries {
    margin: auto;
    display: block;

    button {
      line-height: 1.5rem;
    }

    ul {
      width: 100%;
      margin: 0;
      border: 0;
      background: #efefef;
      border-top: 1px solid #dadada;
      position: relative;

      li {
        a {
          padding: 1rem 1.5rem;
        }
      }
    }
  }
  
}